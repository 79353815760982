<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">意见反馈</div>
    </div>
    <van-loading
      id="loading"
      type="spinner"
      size="24px"
      v-if="loading === true"
    />
    <div v-if="loading === false" class="containerBox">
      <div class="container" v-for="item in recommendList" :key="item.id">
        <div class="contentTime">{{ item.handleTime }}</div>
        <div class="contentBox" @click="goDetail(item)">
          <div class="contentTop">
            <div class="tontentTitle" v-if="item.type === 0">产品体验问题</div>
            <div class="tontentTitle" v-if="item.type === 1">内容问题</div>
            <div class="tontentTitle" v-if="item.type === 2">其它问题</div>
            <div class="contentImg">
              <img src="../../../image/my/j.png" alt="" />
            </div>
          </div>
          <div class="contentCon">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="footerBox">
      <div class="outBtn" @click="goProblemPage()">问题反馈</div>
    </div>
  </div>
</template>

<script>
import { recommendPage } from "../../../api/circle";
export default {
  data() {
    return {
      loading: true,
      recommendList: [],
    };
  },
  mounted() {
    this.loading = true;
    this.getrecommendPage();
  },
  methods: {
    getrecommendPage() {
      recommendPage({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        console.log(res, "反馈列表");
        this.recommendList = res.data.data.items;
        if (res.data.code === 0) {
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    goBack() {
      this.$router.push({ path: "/setIndex" });
    },
    goDetail(item) {
      this.$router.push({ path: "/feedbackDetail", query: { id: item.id } });
    },
    goProblemPage() {
      this.$router.push({ path: "problemPage" });
    },
  },
};
</script>

<style scoped>
/deep/#loading {
  text-align: center;
  margin-top: 100px;
}
.setBox {
  margin-bottom: 100px;
}
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 232px;
}
.formBox {
  margin-top: 32px;
}
.van-cell {
  line-height: 60px;
  font-size: 28px;
  font-weight: 400;
  color: #455669;
}
.back {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.containerBox {
  padding-bottom: 150px;
}
.contentTime {
  height: 130px;
  width: 750px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  line-height: 130px;
  padding-left: 32px;
  box-sizing: border-box;
}
.contentBox {
  /* height: 242px; */
  width: 750px;
  border-bottom: 1px solid #f6f8fa;
}
.contentTop {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}
.contentImg {
  display: flex;
  margin-right: 38px;
}
.contentImg img {
  width: 24px;
  height: 24px;
}
.contentCon {
  width: 686px;
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-bottom: 16px;
  line-height: 44px;
  margin-top: 24px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 2; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}
.tontentTitle {
  width: 504px;
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 32px;
  display: -webkit-box; /* Safari */
  -webkit-line-clamp: 1; /* Safari and Chrome */
  -webkit-box-orient: vertical; /* Safari and Chrome */
  overflow: hidden;
}

.footerBox {
  width: 750px;
  position: fixed;
  background: #fff;
  bottom: 0px;
  left: 0px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.outBtn {
  width: 684px;
  height: 88px;
  background: #0957c3;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  font-size: 32px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  line-height: 88px;
  margin-top: 30px;
  margin-left: 34px;
}
</style>
